<template>
  <div id="appTwo" ref="inner">
    <div id="dd_offset" ref="dd_offset">
      <Header />
      <router-view  v-if="isReload"/>
      <Bottom />
      
      
    </div>
    <el-backtop target="#appTwo"></el-backtop>
  </div>

</template>
<script>
import Header from '@/components/header.vue'
import Bottom from '@/components/bottom.vue'
export default {
  data() {
    return {
      type : true,
      isReload:true
    }
  },
  components: {
    Header,
    Bottom
  },
  mounted (){
    
  },
  computed: {
    
    
  },
  watch: {
      
  },
  methods : {
    
  }
}
</script>
<style lang="less">

body,html{
  // height: 100%;
  margin: 0;
  
  // background-color: #000 !important;
}


#appTwo {
  height: 100vh;
  overflow: auto;
  font-family: 'siyuan' !important;
  // text-align: center;
  color: #2c3e50;
  list-style: none;
  text-decoration: none;
}

#app ::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}


#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.el-backtop{
  z-index: 99 !important;
}
</style>
