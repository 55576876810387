/*
 * @Author: your name
 * @Date: 2021-11-24 18:07:26
 * @LastEditTime: 2022-02-18 10:55:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \website\src\api\index.js
 */
import fetch from "@/network/manager"


// 所有合作伙伴
export function getCooperatorList(params){
    return fetch({
        url : '/website/cooperator/list',
        method : 'get',
        params
    })
}

// 所有类型
export function getTypeList(params){
    return fetch({
        url : '/website/type/list',
        method : 'get',
        params
    })
}

// 所有项目
export function getItemList(params){
    return fetch({
        url : '/website/item/list',
        method : 'get',
        params
    })
}

// 所有轮播图
export function getBannerList(params){
    return fetch({
        url : '/website/banner/list',
        method : 'get',
        params
    })
}

// 所有招聘信息
export function getRecruitList(params){
    return fetch({
        url : '/website/recruit/list',
        method : 'get',
        params
    })
}

// 项目详情  
export function getItemContentInfo(itemId){
    return fetch({
        url : '/website/item/content/info/'+itemId,
        method : 'get'
    })
}

// 项目下轮播图 
export function getItemBannerInfo(params){
    return fetch({
        url : '/website/item/banner/list',
        method : 'get',
        params
    })
}

// 分页查询项目  
export function getItemPage(params){
    return fetch({
        url : '/website/item/page',
        method : 'get',
        params
    })
}

// 项目详情  
export function getItemInfo(itemId){
    return fetch({
        url : '/website/item/info/'+itemId,
        method : 'get'
    })
}

// 新闻咨询  
export function getNewsPage(params){
    return fetch({
        url : '/website/news/page',
        method : 'get',
        params
    })
}

// 热门资讯  
export function getNewsHot(params){
    return fetch({
        url : '/website/news/hot',
        method : 'get',
        params
    })
}

// 新闻咨询详情页  
export function getNewsContentInfo(newsId){
    return fetch({
        url : '/website/news/content/info/'+newsId,
        method : 'get',
    })
}




// ----------------------

// 首页表-全部 
export function getIndexAll(params){
    return fetch({
        url : '/website/index/all',
        method : 'get',
        params
    })
}

// 首页标-通过id查询 
export function getIndexInfo(id){
    return fetch({
        url : `/website/index/info/${id}`,
        method : 'get'
    })
}