import axios from "axios"

// 调用axios.create方法，配置一些属性，返回一个新的axios
const request=axios.create({
    //baseURL: Url,
    //请求超时时间
    timeout: 50000
})

// 请求拦截
request.interceptors.request.use(
	//config 代表是你请求的一些信息
    config => {


        return config
    },
    error => {
        // 对错误请求的处理
        // 弹出错误请求消息

        return Promise.reject(error)
    }
)

//  response拦截器 响应拦截器 请求之后的操作
request.interceptors.response.use(
    config => {
        // config.headers.Authorization = {username : 'wz001',password : 123456}
        return config.data
    },
    error => {

        return Promise.reject(error)
    }
)

export default request;
