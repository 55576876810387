<template>
    <div class="dd_bottom">
        <!-- <div class="dd_bottom_introduce">
            <dl v-for="(item,index) in website" :key="index">
                <dt>{{item.title}}</dt>
                <dd>
                    <p v-for="(itemcontent,indexcontent) in item.content" :key="indexcontent">{{itemcontent.title}}</p>
                </dd>
            </dl>
        </div> -->
        <div class="dd_bottom_company">
            <p><span @click="newOpen">京ICP备18020100号-1</span> | © Copyright 2019. 北京甲板智慧科技有限公司</p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            website : [
                {
                    title : '智慧园林解决方案',
                    content : [
                        {
                            title : '智慧园林',
                            path : ''
                        },
                        {
                            title : '智慧公园',
                            path : ''
                        },
                        {
                            title : '智慧智慧社区',
                            path : ''
                        },
                        {
                            title : '智慧+',
                            path : ''
                        }
                    ]
                },
                {
                    title : '可视化平台',
                    content : [
                        {
                            title : '园林',
                            path : ''
                        },
                        {
                            title : '地产',
                            path : ''
                        },
                        {
                            title : '旅游',
                            path : ''
                        },
                        {
                            title : '水务',
                            path : ''
                        },
                        {
                            title : '零售',
                            path : ''
                        },
                        {
                            title : '教育',
                            path : ''
                        },
                        {
                            title : '展厅',
                            path : ''
                        },
                        {
                            title : '办公',
                            path : ''
                        },
                        {
                            title : '园区',
                            path : ''
                        }
                    ]
                },
                {
                    title : '交互装置',
                    content : [
                        {
                            title : '互动艺术装置',
                            path : ''
                        },
                        {
                            title : '户外家具',
                            path : ''
                        },
                        {
                            title : '监测设备',
                            path : ''
                        },
                        {
                            title : '全部',
                            path : ''
                        }
                    ]
                },
                {
                    title : '机器学习',
                    content : [
                        {
                            title : '图像',
                            path : ''
                        },
                        {
                            title : '视频',
                            path : ''
                        },
                        {
                            title : '文本',
                            path : ''
                        },
                        {
                            title : '语言',
                            path : ''
                        }
                    ]
                },
                {
                    title : '联系我们',
                    content : [
                        {
                            title : '邮箱：dreamdeck@dreeck.com',
                            path : ''
                        },
                        {
                            title : '电话：13581617681',
                            path : ''
                        },
                        {
                            title : '地址：北京市石景山古城南街9号院绿地环球文化金融城5号楼21层',
                            path : ''
                        }
                    ]
                },
            ]
        }
    },
    methods:{
        newOpen(){
            window.open('https://beian.miit.gov.cn')
        }
    }
}
</script>