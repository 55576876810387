<template>
    <div class="dd_head">
        <div class="dd_head_img">
            <img src="../assets/logo.png" @click="homepage" alt="">
            <p>全国热线电话：400-618-2880</p>
        </div>
        <div class="dd_head_icon" @click="checknav">
            <el-icon :size="26" color="#fff" style="vertical-align: middle">
                <Menu />
            </el-icon>
        </div>
        <ul :class="navType == 1?'dd_head_nav':'dd_head_nav dd_head_pull'">
            <li v-for="(item,index) in navList" :key="index" :class="activeNum == index?'active':''" @click="navClick(item,index)">{{ item.name }}</li>
            <!-- <li @click="news" :class="activeNum == -1?'active':''">新闻资讯</li> -->
            <li @click="about" :class="activeNum == -2?'active':''">联系我们</li>
        </ul>
    </div>
</template>
<script>
import { setStore, removeStore } from '../util/store'
import { getIndexAll } from '../api/index'
import { Menu } from '@element-plus/icons-vue';
export default {
    data() {
        return {
            params : {
                showType : 1,
                status : 0,
                type: 1
            },
            navList : [],
            navType : 1,
            activeNum : null
        }
    },
    created(){
        this.getIndexAll()
        let activeNum = window.sessionStorage.getItem('activeNum')
        if (activeNum !== null || activeNum !== undefined) {
            this.activeNum = activeNum
        }
    },
    watch: {

    },
    components: {
        Menu
    },
    methods: {
        checknav(){
            this.navType == 1?this.navType = 0:this.navType = 1
        },
        // 获取一级导航标题
        getIndexAll(){
            getIndexAll(this.params)
                .then(res=>{
                    // console.log(res);
                    this.navList = res.data
                })
        },
        navClick(value,index){
            this.$router.push('/entire?id='+value.id)
            this.navType = 1
            this.activeNum = index
            window.sessionStorage.setItem("activeNum", this.activeNum)
        },
        news(){
          this.$router.push('/news')
          this.navType = 1
          this.activeNum = -1
          window.sessionStorage.setItem("activeNum", this.activeNum)
        },
        about(){
            this.$router.push('/about')
            this.navType = 1
            this.activeNum = -2
            window.sessionStorage.setItem("activeNum", this.activeNum)
        },
        homepage(){
            this.$router.push('/')
            this.navType = 1
            this.activeNum = null
            removeStore("activeNum")
        }
    }
}
</script>
