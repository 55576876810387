
/**
 * 存储sessionStorage
 */

export const setStore = ((name, value)=>{
    window.sessionStorage.setItem(name, value)
})

/**
 * 获取sessionStorage
 */

export const getStore = ((name)=>{
    let value = window.sessionStorage.getItem(name)
    return value
})

/**
 * 删除sessionStorage
 */

export const removeStore = ((name)=>{
    window.sessionStorage.removeItem(name)
})