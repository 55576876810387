import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path : '/',
    name: '首页',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/secondLevel',
    name: '首页 二级',
    component: () => import(/* webpackChunkName: "about" */ '../views/secondLevel.vue')
  },
  {
    path: '/entire',
    name: '智慧公园 | 软件平台 | 互动装置 | 人工智能',
    component: () => import(/* webpackChunkName: "about" */ '../views/entire.vue'),
    // redirect : '/entire'
  },
  {
    path: '/about',
    name: '联系我们',
    component: () => import(/* webpackChunkName: "about" */ '../views/about.vue')
  },
  
]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
router.afterEach(() => {
  window.scrollTo(0,0);
})

export default router
