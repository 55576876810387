/*
 * @Author: your name
 * @Date: 2021-11-23 10:31:33
 * @LastEditTime: 2022-02-17 17:58:45
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \website\src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/index.scss';
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import "./assets/css/font.css"
import 'lib-flexible'
import 'amfe-flexible'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
const app = createApp(App)
app.use(store).use(router).use(ElementPlus).mount('#app')
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}